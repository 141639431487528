.flip-card {
    background-color: transparent;
    width: 350px;
    height: 350px;
    perspective: 1000px;
    
  }

.flip-card-image{
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.flip-card-template{
    width:100%;
    height:100%;
    position: absolute;    
    z-index: 10;
    left:0px;
    top:0px;
}

.flip-card-image-container{
    width: 93%;
    height: 78%;
    left:10px;
    top:10px;
    position: absolute;
}

.flip-card-text{
    position: absolute;
    width: 100%;
    top: 300px;
    z-index: 15;
    padding-left: 6px;
    padding-right: 6px;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner, flip-card:active .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    box-shadow: 0 0 1px rgba(255,255,255,0);
    outline: 1px solid transparent;
}

.flip-card-front {
    background-color: transparent;
    color: black;
}

.flip-card-back {
    background-color: #6a4f6b;
    padding:8px;
    color: white;
    transform: rotateY(180deg);
    display:flex;
    z-index: 30;
}

.flip-card-back > div {
    vertical-align: middle;
    text-align: center;
    margin: auto;
}

.storyboardline{
    width: 100%;
    display: flex;
    margin-bottom:-35px;
}

.storyboard{
    display: "flex";
    flex-flow: "column";
    max-width: 800px;
    margin: auto;
}

@media only screen and (max-width: 880px) {
    .storyboardline {
        margin-bottom:50px;
    }

    .flip-card{
        width: 300px;
        height: 300px;
    }

    .flip-card-text{
        top:250px;
    }
  }