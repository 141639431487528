body{
  font-family: Roboto;
  font-size: 15px;
}

a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #cc5490;
  border-color: #000;
}

.btn-primary:hover {
  color: #fff;
  background-color: #dd84b0;
  border-color: #8979a3;
}

.bodyContainer{
  padding-right: 15px;;
}

.center {
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  text-align: center;
}

.row > :first-child{
  max-width: 30%;
}

.row input[type=text], .row textarea {
  width: 100%;
  margin-top: 3px;
  margin-bottom: 3px;
}

.row input[type=email], .row textarea {
  width: 100%;
  margin-top: 3px;
  margin-bottom: 3px;
}

.nav-item:hover{
  background-color: #e8e8e8;
  font-weight: 500;
}

#registrants td {
  padding:5px;
}

#registrants th {
  padding:5px;
}

#registrants tr:nth-child(even){background-color: #f2f2f2;}

#registrants tr:hover {background-color: #ddd;}

.btn{
  line-height: 1.1;
}

.map-responsive{
  overflow:hidden;
  padding-bottom:56.25%;
  position:relative;
  height:0;
}
.map-responsive iframe{
  left:0;
  top:0;
  height:100%;
  width:100%;
  position:absolute;
}