.photos {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.photo {
    display: inline-block;
    padding: 5px;
}

@media only screen and (max-width: 600px) {
    .photos{
        justify-content: space-evenly;
    }
}

.copyright{
    font-size:smaller;
    color:grey;
    padding-left: 4px;
    padding-bottom: 2px;
}