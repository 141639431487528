#info {
    width:70%;
    border: 2px solid #FDC4CF;
    background: #ffffff99;
    border-radius: 25px;
    margin: auto;
    margin-top: 30px;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 0px;
    margin-bottom: 30px;
}

#info:last-child{
    padding-bottom: 0px;
}

@media only screen and (max-width: 700px) {
    #info {
        width: 100%;
    }
  }